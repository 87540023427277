import './PageName.css';

const PageName = (props) => {
    const style = props.m0 ? {marginBottom: 0} : {};

    return (
        <h1 className={'page-name'} style={style}>{props.children}</h1>
    );
};

export default PageName;