import {Helmet} from "react-helmet-async";
import {useContext, useEffect, useReducer, useState} from "react";
import {initialMainPageReducer, mainPageReducer} from "../../reducers/MainPageReducer";
import {useNavigate, useParams} from "react-router-dom";
import {initialProductReducer, productReducer} from "../../reducers/ProductReducer";
import {getProductImages, getProductInfo} from "../../api/ProductInfo";
import {getNewProductsCarousel} from "../../api/Carousels";
import {priceProcessing} from "../../helpers/Processors";
import {breadcrumbsReducer, initialBreadcrumbsReducer} from "../../reducers/BreadcrumbsReducer";
import {getBreadcrumbsPath} from "../../api/Breadcrumbs";
import {addToCartHandler} from "../CartPage/CartHandlers";
import CartContext from "../../store/cart-context";
import LoginContext from "../../store/login-context";
import {useTranslation} from "react-i18next";

import Container from "../../components/container/Container";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Line from "../../components/UI/Line";
import ProductImageCarousel from "../../components/carousels/ProductImageCarousel";
import Select from "../../components/UI/Form/Select";
import ProductFeature from "./ProductFeature";
import HollowButton from "../../components/UI/Buttons/HollowButton";
import SolidButton from "../../components/UI/Buttons/SolidButton";
import ProductCarousel from "../../components/carousels/ProductCarousel";
import Ball from "../../components/Ball";
import ComponentLoading from "../../components/loading/ComponentLoading";
import OneClickBuyModal from "../../components/modals/OneClickBuyModal";
import LanguageSetter from "../../components/container/LanguageSetter";

import './ProductPage.css';

const ProductPage = (props) => {
    const { t, i18n } = useTranslation();
    const {subcategory, product_name} = useParams();
    const navigate = useNavigate();
    const cartCtx = useContext(CartContext);
    const loginCtx = useContext(LoginContext);

    const [metadata, setMetadata] = useState({});
    const [isOneClickBuyOpened, setOneClickBuyOpened] = useState(false);
    const [itemAmount, setItemAmount] = useState(1);
    const [carouselState, carouselDispatch] = useReducer(mainPageReducer, initialMainPageReducer);
    const [productState, productDispatch] = useReducer(productReducer, initialProductReducer);
    const [breadcrumbsState, breadcrumbsDispatch] = useReducer(breadcrumbsReducer, initialBreadcrumbsReducer);

    useEffect(() => {
        getBreadcrumbsPath(breadcrumbsDispatch, subcategory);
        const fetchData = async () => {
            const productInfo = await getProductInfo(productDispatch, product_name);
            if (productInfo.id === undefined || productInfo['subcategory_name_' + i18n.resolvedLanguage] !== subcategory) {
                navigate('/' + i18n.resolvedLanguage + '/shop');
            } else {
                const metadata = {
                    title: productInfo['meta_title_' + i18n.resolvedLanguage],
                    description: productInfo['meta_description_' + i18n.resolvedLanguage]
                }
                if (metadata.title === '') {
                    metadata.title = productInfo['name_' + i18n.resolvedLanguage];
                }
                if (metadata.description === '') {
                    metadata.description = productInfo['description_' + i18n.resolvedLanguage];
                }
                setMetadata(metadata);

                productDispatch({type: 'LoadProductInfo', payload: productInfo});
                const imageData = await getProductImages(productDispatch, productInfo.id);
                getNewProductsCarousel(carouselDispatch, productInfo.id);
                productDispatch({type: 'LoadProductImages', payload: imageData});
            }
        }
        fetchData();
    }, [subcategory, product_name]);

    const toggleOneClickBuyModal = () => {
        setOneClickBuyOpened(prevState => !prevState);
    };

    const addToCartButtonHandler = () => {
        addToCartHandler(productState.product.id, cartCtx, loginCtx, itemAmount);
        cartCtx.openCart();
    };

    if (productState.productLoaded && (productState.product.id === null)) {
        navigate('/' + i18n.resolvedLanguage + '/shop');
    }

    const hierarchy = [
        {
            name: t('productPage.breadcrumbs.categories'),
            url: '/' + i18n.resolvedLanguage + '/shop'
        },
        {
            name: breadcrumbsState.breadcrumbs['category_' + i18n.resolvedLanguage]
        },
        {
            name: breadcrumbsState.breadcrumbs['subcategory_' + i18n.resolvedLanguage],
            url: '/' + i18n.resolvedLanguage + '/shop/' + breadcrumbsState.breadcrumbs['link_name_' + i18n.resolvedLanguage]
        },
        {
            name: productState.product['name_' + i18n.resolvedLanguage]
        }
    ];

    let productFeatures = null;
    if (productState.product.feature_ru !== '') {
        productFeatures = productState.product['feature_' + i18n.resolvedLanguage].split('$').map((feature, index) => {
            return <ProductFeature text={feature} key={index} />
        });
    }
    let productControls;
    if (productState.product.ProductAvailable === '1') {
        productControls = (
            <div className='product-page__controls'>
                <div className='d-flex align-items-center justify-content-between' style={{marginBottom: '15px'}}>
                    <h4 className='white-text'>{t('productPage.controls.quantity')}:</h4>
                    <Select
                        id='count'
                        title={t('productPage.controls.quantity')}
                        count
                        onChange={setItemAmount}
                    />
                </div>
                <Col sm={12} col={6}>
                    <SolidButton text={t('productPage.controls.buyOneClick')} secondary className='w-100' onClick={toggleOneClickBuyModal} />
                </Col>
                <div className='d-flex align-items-center' style={{marginTop: '10px'}}>
                    <Col sm={12} col={6} className='d-flex' style={{marginRight: '10px'}}>
                        <SolidButton text={t('productPage.controls.buyButton')} primary className='w-100' id={productState.id} amount={itemAmount}  onClick={addToCartButtonHandler} />

                    </Col>
                </div>
            </div>
        );
    } else {
        productControls = (
            <div className='nu_este_stock'>{t('nu_este_stock')}</div>
        );
    }

    let discount = null;
    let oldPriceClass = 'primary-text';
    if (productState.product.discount !== null) {
        discount = (
            <h2 className='secondary-text'>
                {priceProcessing(productState.product.discount)}
            </h2>
        );
        oldPriceClass += ' old-price';
    }

    let helmetTitle = null;
    let helmetDescription = null;
    if (metadata.title !== '') {
        helmetTitle = (
            <>
                <title>{metadata.title}</title>
                <meta
                    name="title"
                    content={metadata.title}
                />;
            </>
        )
    }
    if (metadata.description !== '') {
        helmetDescription = (
            <meta
                name="description"
                content={metadata.description}
            />
        );
    }

    let productStructuredData = {};
    if (productState.productImagesLoaded) {
        productStructuredData = {
            "@context": "https://schema.org",
            "@type": "Product",
            name: productState.product['name_' + i18n.resolvedLanguage],
            description: productState.product['description_' + i18n.resolvedLanguage],
            image: productState.productImages[0].image,
            category: breadcrumbsState.breadcrumbs['subcategory_' + i18n.resolvedLanguage],
            brand: {
                "@type": "Brand",
                name: productState.product.brand_name
            },
        };
    }


    return (
        <LanguageSetter lang={props.lang}>
            <Helmet>
                {helmetTitle}
                {helmetDescription}
            </Helmet>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(productStructuredData),
                }}
            />
            <main className='p-relative'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col col={12} className='product-page__breadcrumbs'>
                            <Breadcrumbs
                                hierarchy={hierarchy}
                            />
                            <Line mb='20px' dark />
                        </Col>
                        <Col sm={12} md={6} lg={5}>
                            {
                                productState.productImagesLoaded ? (
                                    <ProductImageCarousel
                                        carouselInfo={productState.productImages}
                                    />
                                ) : <ComponentLoading height={'100vh'} />
                            }
                        </Col>
                        <Col sm={12} md={6} lg={5}>
                            <div className='product-page__info'>
                                <div className='product-page__header'>
                                    <div>
                                        <h1 className='product-page__title white-text'>{productState.product['name_' + i18n.resolvedLanguage]}</h1>
                                    </div>
                                    <div className='product-page__prices-container d-flex align-items-center'>
                                        <div className='product-page__prices'>
                                            {discount}
                                            <span className={oldPriceClass}>{priceProcessing(productState.product.price)}</span>
                                        </div>
                                        <HollowButton secondary onClick={props.toggleDiscountModal} text={t('promo_want')} />
                                    </div>
                                </div>
                                <div>
                                    <div className='product-page__product-features'>
                                        <Line mb='32px' />
                                        {productFeatures}
                                        <Line mt='32px' />
                                    </div>
                                </div>
                                {
                                    productControls
                                }
                                <div className='product-page__description'>
                                    <p className='dark-text'>{productState.product['description_' + i18n.resolvedLanguage]}</p>
                                </div>
                            </div>
                        </Col>
                        <Col col={12}>
                            <Line dark mt='40px' mb='40px' />
                            {carouselState.newLoaded ? (
                                <ProductCarousel
                                    title={t('carousels.newCarouselHeader')}
                                    carouselInfo={carouselState.newCarouselInfo}
                                    // .filter(item => item.product_id !== id)
                                />
                            ) : <ComponentLoading height={'60vh'} />}
                        </Col>
                    </Row>
                    <Ball
                        background='#E4B948'
                        top={0}
                        right={0}
                    />
                    <Ball
                        background='#FE7A00'
                        top='600px'
                        left={0}
                    />
                    <Ball
                        background='#FE0098'
                        bottom='600px'
                        right={0}
                    />
                </Container>
                {isOneClickBuyOpened && (
                    <OneClickBuyModal
                        onClose={toggleOneClickBuyModal}
                        id={productState.product.id}
                        title={productState.product['name_' + i18n.resolvedLanguage]}
                        price={productState.product.price}
                        discount={productState.product.discount}
                        subcategory_id={productState.product.subcategory_id}
                        img={productState.product.main_image}
                        img_alt={productState.product.alt}
                        img_title={productState.product.title}
                        amount={itemAmount}
                    />
                )}
            </main>
        </LanguageSetter>
    );
}

export default ProductPage;