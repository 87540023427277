import {useContext, useEffect, useState} from "react";
import {getCities} from "../../api/City";
import useInput from "../../hooks/use-input";
import LoginContext from "../../store/login-context";
import {getCurrentUser} from "../../api/Account";
import {useNavigate} from "react-router-dom";

import SidebarHeader from "../../components/UI/Headers/SidebarHeader";
import SidebarContainer from "../../components/container/SidebarContainer";
import Input from "../../components/UI/Form/Input";
import Line from "../../components/UI/Line";
import Checkbox from "../../components/UI/Form/Checkbox";
import Radio from "../../components/UI/Form/Radio";
import Select from "../../components/UI/Form/Select";

import './CheckoutInfo.css';
import {isEmail, isPhone} from "../../helpers/Validator";
import {useTranslation} from "react-i18next";

const CheckoutInfo = (props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const loginCtx = useContext(LoginContext);

    const [cities, setCities] = useState({cities: []});
    const [currentUser, setCurrentUser] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            setCities(await getCities());

            if (loginCtx.isLoggedIn()) {
                const tmpUserResponse = await getCurrentUser(loginCtx);
                if (tmpUserResponse.error === 1) navigate('/' + i18n.resolvedLanguage + '/login');
                setCurrentUser(tmpUserResponse);
            }
        };
        fetchData();
    }, []);

    const citySelectOptions = cities.cities.map(city => {
        if(city.id >1) {
            return {
                value: city.id,
                text: city['name_' + i18n.resolvedLanguage] + ' (Новая Почта)'
            };
        } else {
            return {
                value: city.id,
                text: city['name_' + i18n.resolvedLanguage]
            };
        }
        
    });

    const commentOnChangeHandler = (e) => {
        props.onComment(e.target.value);
    };

    const {
        value: FLName,
        isValid: enteredFLNameIsValid,
        hasError: FLNameInputHasError,
        valueChangeHandler: FLNameChangedHandler,
        inputBlurHandler: FLNameBlurHandler
    } = useInput(value => value.trim() !== '');

    const {
        value: email,
        isValid: enteredEmailIsValid,
        hasError: emailInputHasError,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler
    } = useInput(value => value.trim() !== '' && isEmail(value));

    const {
        value: phone,
        isValid: enteredPhoneIsValid,
        hasError: phoneInputHasError,
        valueChangeHandler: phoneChangedHandler,
        inputBlurHandler: phoneBlurHandler
    } = useInput(value => value.trim() !== '' && isPhone(value));

    const {
        value: address,
        isValid: enteredAddressIsValid,
        hasError: addressInputHasError,
        valueChangeHandler: addressChangedHandler,
        inputBlurHandler: addressBlurHandler
    } = useInput(value => value.trim() !== '');

    const addressChangeHandler = (e) => {
        addressChangedHandler(e);
        props.onAddress(e.target.value);
        props.onAddressValid(e.target.value.trim() !== '');
    }

    const addressBlurChangeHandler = () => {
        addressBlurHandler();
        props.onAddressValid(enteredAddressIsValid);
    }

    const FLNameChangeHandler = (e) => {
        FLNameChangedHandler(e);
        props.onFLName(e.target.value);
        props.onFLNameValid(e.target.value.trim() !== '');
    }

    const FLNameBlurChangeHandler = () => {
        FLNameBlurHandler();
        props.onFLNameValid(enteredFLNameIsValid);
    }

    const emailChangeHandler = (e) => {
        emailChangedHandler(e);
        props.onEmail(e.target.value);
        props.onEmailValid(e.target.value.trim() !== '' && isEmail(e.target.value.trim()));
    }

    const emailBlurChangeHandler = () => {
        emailBlurHandler();
        props.onEmailValid(enteredEmailIsValid);
    }

    const phoneChangeHandler = (e) => {
        phoneChangedHandler(e);
        props.onPhone(e.target.value);
        props.onPhoneValid(e.target.value.trim() !== '' && isPhone(e.target.value.trim()));
    }

    const phoneBlurChangeHandler = () => {
        phoneBlurHandler();
        props.onPhoneValid(enteredPhoneIsValid);
    }

    return (
        <div className='checkout-info'>
            <SidebarHeader text={t('checkoutPage.deliverySidebarHeader')} primary />
            <SidebarContainer>
                {
                    citySelectOptions.length > 0 && (
                        <Select
                            id='city'
                            title={t('checkoutPage.forms.city')}
                            options={citySelectOptions}
                            fullWidth
                            value={props.selectedCity}
                            onChange={props.onSelectCity}
                        />
                    )
                }
                <Input
                    type='address'
                    placeholder={t('checkoutPage.forms.address')}
                    value={address}
                    isError={addressInputHasError}
                    onChange={addressChangeHandler}
                    onBlur={addressBlurChangeHandler}
                />
                {
                    !loginCtx.isLoggedIn() && (
                        <div>
                            <Input
                                type='text'
                                placeholder={t('checkoutPage.forms.FLName')}
                                value={FLName}
                                isError={FLNameInputHasError}
                                onChange={FLNameChangeHandler}
                                onBlur={FLNameBlurChangeHandler}
                            />
                            <Input
                                type='phone'
                                placeholder={t('checkoutPage.forms.phoneNumber')}
                                value={phone}
                                isError={phoneInputHasError}
                                onChange={phoneChangeHandler}
                                onBlur={phoneBlurChangeHandler}
                            />
                        </div>
                    )
                }
                {currentUser.fName && (
                    <Input type='text' value={currentUser.fName + ' ' + currentUser.lName} readonly />
                )}
                {currentUser.email && (
                    <Input type='email' value={currentUser.email} readonly />
                )}
                {currentUser.phone && (
                    <Input type='tel' value={currentUser.phone} readonly />
                )}
                <Input
                    type='text'
                    placeholder={t('checkoutPage.forms.comment')}
                    value={props.comment}
                    onChange={commentOnChangeHandler}
                />
                <Radio text={t('checkoutPage.forms.cashRadio')} name='payMethod' onChange={() => props.onPaymentMethod(1)} checked={props.paymentMethod === 1} />
                <Radio text={t('checkoutPage.forms.cardRadio')} name='payMethod' onChange={() => props.onPaymentMethod(2)} checked={props.paymentMethod === 2} />
                <Line mt='30px' mb='30px' />
                <Checkbox text={t('checkoutPage.forms.noCallCheckbox')} value={!props.recall} onChange={() => props.onRecall(prevState => !prevState)} />
            </SidebarContainer>
        </div>
    );
};

export default CheckoutInfo;