import {useEffect, useState} from "react";

import BigAngleIcon from "../../assets/icons/BigAngleIcon";

import "./ScrollToTopButton.css"

const ScrollToTopButton = () => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        window.onscroll = () => setIsShown(window.scrollY !== 0);

        return () => (window.onscroll = null);
    });

    const scrollToTopHandler = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };

    const classes = isShown ? '' : 'd-none';

    return (
      <button className={"to_top_button" + classes} onClick={scrollToTopHandler}>
        <BigAngleIcon />
      </button>
    );
};

export default ScrollToTopButton;