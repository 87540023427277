import {useContext} from "react";
import {useNavigate} from "react-router-dom";
import CartContext from "../../../store/cart-context";
import {useTranslation} from "react-i18next";

import SidebarHeader from "../../UI/Headers/SidebarHeader";
import SidebarContainer from "../../container/SidebarContainer";
import SolidButton from "../../UI/Buttons/SolidButton";
import HollowButton from "../../UI/Buttons/HollowButton";
import CartWidgetItem from "../../../pages/CartPage/CartWidgetItem";
import EmptyCart from "../../UI/Error/EmptyCart";

import './CartWidget.css';

const CartWidget = (props) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const cartCtx = useContext(CartContext);

    let cartItems = <EmptyCart />;
    if (cartCtx.items.length > 0) {
        cartItems = cartCtx.items.map(item => (
            <CartWidgetItem
                key={item.id}
                id={item.id}
                product_name_link={item['product_name_link_' + i18n.resolvedLanguage]}
                subcategory_id={item.subcategory_id}
                subcategory_name={item['subcategory_name_' + i18n.resolvedLanguage]}
                title={item['product_name_' + i18n.resolvedLanguage]}
                price={item.price}
                discount={item.discount}
                count={item.amount}
                img={item.image}
                img_alt={item.alt}
                img_title={item.title}
            />
        ));
        cartItems = <SidebarContainer>{cartItems}</SidebarContainer>;
    }

    const makeOrderButtonHandler = () => {
        navigate('/' + i18n.resolvedLanguage + '/cart');
        props.onClose();
    }
    const MakeOrderButtonDisplay = cartCtx.totalAmount > 0;

    return (
        <article className='cart-widget'>
            <aside className='w-100'>
                <SidebarHeader text={t('cart.widgetHeader')} primary className='text-center' onClick={props.onClose} />
                <div className='cart-widget__items'>
                    {cartItems}
                </div>
                <div className='cart-widget__controls'>
                    <SidebarContainer className='text-center'>
                        <HollowButton primary text={t('cart.continueShoppingButton')} className='w-75 mb-1' onClick={props.onClose} />
                        { MakeOrderButtonDisplay && <SolidButton primary text={t('cart.makeOrderButton')} className='w-75' onClick={makeOrderButtonHandler} /> }
                    </SidebarContainer>
                </div>
            </aside>
        </article>
    );
};

export default CartWidget;